@font-face {
  font-family: 'CooperBT';
  src: url('font/Cooper_BT_Font_Family/CooperBlkBT-Regular.ttf') format('truetype');
  font-weight: normal; 
  font-style: normal; 
}

@font-face {
  font-family: 'AnonymousPro';
  src: url('font/Anonymous_Pro_Font_Family/AnonymousPro-Regular.ttf') format('truetype');
  font-weight: normal; 
  font-style: normal; 
}